@use '@plus/ds-tokens/files/generated/tokens/variables' as v;

@mixin globalMenuHeights {
	$header-subnav-height: 48px;

	@media screen and (min-width: v.$breakpoints-xxs) {
		--bottom-nav-height: 57px;
		--header-margin-bottom: 8px;
		--header-height: 48px;
		--header-height-with-subnav: calc(var(--header-height) + #{$header-subnav-height});
	}
	@media screen and (min-width: v.$breakpoints-xs) {
		--header-height: 56px;
		--header-margin-bottom: 12px;
	}
	@media screen and (min-width: v.$breakpoints-sm) {
		--bottom-nav-height: 73px;
		--header-margin-bottom: 16px;
	}
	@media screen and (min-width: v.$breakpoints-md) {
		--header-margin-bottom: 24px;
	}
	@media screen and (min-width: v.$breakpoints-lg) {
		--header-height: 72px;
		--header-margin-bottom: 32px;
	}
	@media screen and (min-width: v.$breakpoints-lg) and (hover: hover) {
		--header-height: 80px;
		// there is no subnav for desktop devices anymore
		--header-height-with-subnav: var(--header-height);
		--bottom-nav-height: 0px;
	}
	@media screen and (min-width: v.$breakpoints-xl) {
		--header-height: 80px;
		--header-margin-bottom: 48px;
	}
	@media screen and (min-width: v.$breakpoints-xl) and (hover: none) {
		--bottom-nav-height: 0px;
	}
}
