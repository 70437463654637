@use '@plus/ds-tokens/files/generated/tokens/variables' as tokens;

.ui-headline1,
.ui-headline2,
.ui-headline3,
.ui-headline4,
.ui-headline5,
.ui-headline6 {
	margin: 0;
	color: var(--colors-ui-highlight);
	word-wrap: break-word;
}

.ui-headline1 {
	font: var(--typography-interface-title3-bold);

	@media (min-width: tokens.$breakpoints-lg) {
		font: var(--typography-interface-title2-bold);
	}
	@media (min-width: tokens.$breakpoints-xxl) {
		font: var(--typography-interface-large-title3-bold);
	}
}

.ui-headline2 {
	font: var(--typography-interface-title2-bold);
}

.ui-headline3 {
	font: var(--typography-interface-caption-bold);

	@media (min-width: tokens.$breakpoints-md) {
		font: var(--typography-interface-title3-bold);
	}
	@media (min-width: tokens.$breakpoints-xl) {
		font: var(--typography-interface-title2-bold);
	}
}

.ui-headline4 {
	font: var(--typography-tv-small);
}
