// Use local SCSS files!
@use 'libs/ui/src/styles/layout.scss' as l;

// Import global css variables
@use './style-variables' as *;

// Reset CSS
@use 'style-reset' as *;

// Import UI Styles
@use 'libs/ui/src/styles/styles' as *;

@font-face {
	font-family: 'RTL United Text';
	src: url('~@plus/ds-tokens/files/fonts/rtl-united-text/woff2/RTLUnitedText-Regular.woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'RTL United Text';
	src: url('~@plus/ds-tokens/files/fonts/rtl-united-text/woff2/RTLUnitedText-RegularItalic.woff2');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'RTL United Text';
	src: url('~@plus/ds-tokens/files/fonts/rtl-united-text/woff2/RTLUnitedText-Bold.woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'RTL United Text';
	src: url('~@plus/ds-tokens/files/fonts/rtl-united-text/woff2/RTLUnitedText-BoldItalic.woff2');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'RTL United Text';
	src: url('~@plus/ds-tokens/files/fonts/rtl-united-text/woff2/RTLUnitedText-Light.woff2');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'RTL United Text';
	src: url('~@plus/ds-tokens/files/fonts/rtl-united-text/woff2/RTLUnitedText-LightItalic.woff2');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'RTL United Calculation';
	src: url('~@plus/ds-tokens/files/fonts/rtl-united-calculation/woff2/RTLUnitedCalculation-Regular.woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'RTL United Calculation';
	src: url('~@plus/ds-tokens/files/fonts/rtl-united-calculation/woff2/RTLUnitedCalculation-Bold.woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DeezerProduct';
	src: url(../src/assets/fonts/DeezerProduct-Bold.woff2);
	font-weight: 700;
	font-style: bold;
	font-display: swap;
}

:root {
	--body-max-width: #{l.$body-max-width};
}

/**
 * Global overrides for all Material UI components
 */
.cdk-overlay-container {
	z-index: 140001;

	.podcast-backdropBackground,
	.watch-backdropBackground {
		background-color: var(--colors-special-always-dark-overlay-high);
	}

	/**
	 * Global overrides for Material UI menu panel (aka context menu)
	 */
	.mat-mdc-menu-panel {
		background-color: var(--colors-background-variant);

		border-radius: var(--radii-md);
		box-shadow: none !important;
		max-width: none;

		.mat-mdc-menu-item {
			display: flex;
			align-items: center;
			gap: var(--sizes-8);
			color: var(--colors-ui-highlight);
			background-color: var(--colors-background-variant);
			font: var(--typography-interface-body-title);
			padding: var(--sizes-12) var(--sizes-24);

			&.menu-item-no-padding {
				padding: 0;
			}

			&:hover {
				background-color: var(--colors-on-background-low-emphasis);
			}

			ui-icon,
			.mat-mdc-menu-submenu-icon {
				color: var(--colors-on-ui-low-emphasis);
			}

			.mat-mdc-menu-item-text {
				width: 100%;
			}

			&:hover ui-icon,
			&:hover .mat-mdc-menu-submenu-icon {
				color: var(--colors-on-ui-high-emphasis);
			}
		}
	}

	// TODO
	// I dunno why the opacity and scaling suddenly changes, this is a temporary fix for the context menu in the
	// music header component
	.fix {
		opacity: 1 !important;
		transform: scale(1) !important;
	}
}
