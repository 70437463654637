@use 'mixins/usability';
@use '../lib/button/button.component' as button;

a {
	@include usability.focusStyle;
}

.ui-button-link {
	text-decoration: none;

	@include button.generalButtonStyles();
	@include button.primaryButton();
	@include button.secondaryButton();
	@include button.ghostButton();
	@include button.subMenuButton();
	border-radius: var(--radii-sm);
}
