@use '@plus/ds-tokens/files/generated/tokens/variables' as tokens;

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
	--mdc-secondary-navigation-tab-container-height: 48px;
	--mat-tab-header-divider-height: 1px;
	--mat-tab-header-divider-color: var(--colors-ui-medium-contrast);
	--mat-tab-header-inactive-label-text-color: var(--colors-on-ui-low-emphasis);
	--mdc-tab-indicator-active-indicator-color: var(--colors-on-ui-high-emphasis);

	font: var(--typography-interface-body-title);

	.mat-mdc-tab-header {
		margin-bottom: 16px;

		@media (min-width: tokens.$breakpoints-xl) {
			margin-bottom: 24px;
		}

		.mat-mdc-tab-label-container {
			overflow: unset;
		}
	}

	.mdc-tab {
		font-family: inherit !important;
		font-size: inherit !important;
		letter-spacing: inherit !important;
		line-height: inherit !important;
		font-weight: inherit !important;
	}

	.mat-mdc-focus-indicator:focus-visible {
		outline: 1px solid var(--colors-ui-highlight);
		outline-offset: -1px;
	}
}

.watch-tab-bar {
	&.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs {
		> .mat-mdc-tab-header .mat-mdc-tab {
			flex: 1 0 50%;
		}
	}
}
