@use '@plus/ds-tokens/files/generated/tokens/variables.scss' as v;

// Maximum of width of body, only defined locally and not being moved into DS-Tokens!
$body-max-width: 1600px;

:root {
	--body-max-width: 1600px;
}

/**
 * Global page paddings
 */
@mixin globalPagePadding() {
	@media screen and (min-width: v.$breakpoints-xxs) and (max-width: #{v.$breakpoints-xs - 1px}) {
		--global-page-padding: #{v.$sizes-16};
	}
	@media screen and (min-width: v.$breakpoints-xs) and (max-width: #{v.$breakpoints-sm - 1px}) {
		--global-page-padding: #{v.$sizes-24};
	}
	@media screen and (min-width: v.$breakpoints-sm) and (max-width: #{v.$breakpoints-md - 1px}) {
		--global-page-padding: #{v.$sizes-32};
	}
	@media screen and (min-width: v.$breakpoints-md) and (max-width: #{v.$breakpoints-lg - 1px}) {
		--global-page-padding: #{v.$sizes-48};
	}
	@media screen and (min-width: v.$breakpoints-lg) and (max-width: #{v.$breakpoints-xl - 1px}) {
		--global-page-padding: #{v.$sizes-64};
	}
	@media screen and (min-width: v.$breakpoints-xl) and (max-width: #{v.$breakpoints-xxl - 1px}) {
		--global-page-padding: #{v.$sizes-96};
	}
	@media screen and (min-width: v.$breakpoints-xxl) {
		--global-page-padding: 0;
	}
}

/**
 * Layout behavior of global page wrapper.
 */
@mixin globalPageWrapper() {
	margin: 0 auto;
	padding: 0 var(--global-page-padding);

	@media screen and (min-width: v.$breakpoints-xxl) {
		max-width: $body-max-width;
	}
}
