*,
*::after,
*::before {
	box-sizing: inherit;
}

* {
	font-size: 100%;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	overflow: hidden;
}

html {
	background-color: var(--colors-background);
	box-sizing: border-box;
	color: var(--colors-ui-highlight);
	font-family: 'RTL United Text', sans-serif;
	line-height: 1;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	overflow-y: scroll;
}

body.no-scroll {
	overflow: hidden;
	height: 100vh;
}

button {
	cursor: pointer;
}

a {
	color: var(--colors-ui-highlight);
}

em {
	font-style: italic;
}

strong {
	font-weight: 700;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
