@use '../../styles/mixins/usability';
@use '../../styles/variables' as var;
@use 'button.mixins' as button;

$animation-duration: 600ms;
$animation: $animation-duration var.$animation-timing-function;

@mixin generalButtonStyles() {
	@include button.commonBase;
	@include usability.focusStyle;

	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-radius: var(--radii-md);
	cursor: pointer;
	justify-content: center;
	overflow: hidden;
	position: relative;
	transition:
		background-color $animation,
		color $animation,
		border $animation;
	z-index: 1;

	span {
		color: inherit;
		font: inherit;
		white-space: nowrap;
	}

	ui-icon {
		display: inline-flex;
		align-items: center;
		color: inherit;
	}

	ui-icon + span {
		margin-left: var(--sizes-8);
	}
	span + ui-icon {
		margin-left: var(--sizes-8);
	}

	&.narrow {
		border-radius: var(--radii-sm);
		ui-icon + span {
			margin-left: var(--sizes-4);
		}

		span + ui-icon {
			margin-left: var(--sizes-4);
		}
	}

	&:disabled {
		cursor: not-allowed;
		* {
			pointer-events: none;
		}
	}
}

@mixin primaryButton() {
	&.primary {
		background-color: var(--colors-ui-highlight);
		border-color: var(--colors-ui-highlight);
		color: var(--colors-on-ui-highlight-high-emphasis);

		&:hover,
		&:focus-visible {
			background-color: var(--colors-ui-high-contrast);
			border-color: var(--colors-ui-high-contrast);
			color: var(--colors-on-ui-high-emphasis);
		}

		&:active {
			background-color: var(--colors-ui-normal-contrast);
			border-color: var(--colors-ui-normal-contrast);
			color: var(--colors-on-ui-medium-emphasis);
		}

		&:disabled {
			background-color: var(--colors-ui-low-contrast);
			border-color: var(--colors-ui-low-contrast);
			color: var(--colors-on-ui-low-emphasis);
		}
	}
}

@mixin secondaryButton() {
	&.secondary {
		background-color: transparent;
		border-color: var(--colors-ui-high-contrast);
		color: var(--colors-on-background-high-emphasis);

		&:hover,
		&:focus-visible {
			background-color: transparent;
			border-color: var(--colors-on-background-high-emphasis);
			color: var(--colors-on-background-high-emphasis);
		}

		&:active {
			background-color: var(--colors-ui-low-contrast);
			border-color: var(--colors-ui-medium-contrast);
			color: var(--colors-on-ui-medium-emphasis);
		}

		&:disabled {
			background-color: transparent;
			border-color: var(--colors-ui-medium-contrast);
			color: var(--colors-on-ui-low-emphasis);
		}
	}
}

@mixin ghostButton() {
	&.ghost {
		background-color: transparent;
		border-color: transparent;
		color: var(--colors-on-background-high-emphasis);

		&:hover,
		&:focus-visible {
			background-color: var(--colors-ui-low-contrast);
			border-color: transparent;
		}

		&:active {
			background-color: var(--colors-ui-medium-contrast);
			border-color: transparent;
		}

		&:disabled {
			background-color: transparent;
			border-color: transparent;
			color: var(--colors-on-background-low-emphasis);
		}
	}
}

@mixin subMenuButton() {
	&.submenu {
		background-color: var(--colors-ui-low-contrast);
		border-color: transparent;
		color: var(--typography-interface-body-title-bold);

		&:hover,
		&:focus-visible,
		&:active {
			background-color: var(--colors-ui-high-contrast);
		}
	}
}

:host {
	display: block;
}

:host.disabled {
	pointer-events: none;
}

.ui-button {
	@include generalButtonStyles();
	@include primaryButton();
	@include secondaryButton();
	@include ghostButton();
	@include subMenuButton();

	&.text {
		font: var(--typography-interface-body);
		text-decoration: underline;
	}

	&.plain,
	&.text {
		background-color: transparent;
		border: none;
		border-radius: 0;
		padding: 0;
		min-height: auto;
		color: var(--colors-on-background-high-emphasis);

		&:disabled {
			color: var(--colors-ui-medium-contrast);
		}
	}
}

:host-context(.choice-chip) .ui-button {
	border-radius: var(--radii-circular);
}
