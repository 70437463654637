@mixin commonBase {
	display: inline-flex;
	font: var(--typography-interface-button);
	padding: 0 var(--sizes-24);
	min-height: var(--sizes-48);

	&.narrow {
		padding: 0 var(--sizes-12);
		min-height: var(--sizes-32);
		font: var(--typography-interface-caption-title);
	}

	&.full {
		display: flex;
		justify-content: center;
		width: 100%;
	}
}
