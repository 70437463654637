@use '@angular/material' as mat;
@use '@plus/ds-tokens/files/generated/tokens/variables' as v;
@use 'mixins/material/tabs';
@include mat.elevation-classes();
@include mat.app-background();

// Spinner
.mat-mdc-progress-spinner,
.mat-mdc-spinner {
	svg {
		circle {
			stroke: currentColor !important;
		}
	}
}

// legacy
.mat-progress-spinner,
.mat-spinner {
	svg {
		height: inherit !important;
		width: inherit !important;
		circle {
			stroke: currentColor !important;
		}
	}
}

// Snackbar
.mat-mdc-snack-bar-container {
	bottom: 0;
	margin: 0 !important;
	position: relative !important;

	@media (min-width: 0px) and (max-width: #{v.$breakpoints-sm - 1}) {
		bottom: 60px !important;
	}
	@media (min-width: v.$breakpoints-sm) and (max-width: #{v.$breakpoints-lg - 1}) {
		bottom: 74px !important;
	}

	.mdc-snackbar__surface {
		background: none !important;
		border-radius: 0 !important;
		box-shadow: none !important;
		max-width: 100% !important;
		min-width: auto !important;
		padding: 0 !important;
		width: auto !important;
	}
}
